import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import LoginPage from "./screen/LoginPage";
import { ProtectedLayout } from "./screen/ProtectedLayout";
import { QueryClientProvider } from "@tanstack/react-query";

import { queryClient } from "./query";
import { ToastProvider } from "./modules/Toast/components/ToastProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/*" element={<ProtectedLayout />} />

      <Route path="/login" element={<LoginPage />} />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <RouterProvider router={router} />
      </ToastProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
