import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../components/Title";
import { Order, queryOrderKey, useQueryOrder } from "../query/useQueryOrder";
import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  styled,
  TablePagination,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { list } from "../api/orders";

const RejectedButton = styled(Button)({
  "&.Mui-disabled": {
    color: "#ff0000",
  },
  "&.MuiButton-outlined.Mui-disabled": {
    background: "#ff0000",
    color: "#fff",
  },
  "&.MuiButton-contained.Mui-disabled": {
    background: "#ff0000",
    color: "#fff",
  },
});

const PendingButton = styled(Button)({
  "&.Mui": {
    color: "#ffa300",
  },
  "&.MuiButton-outlined": {
    background: "#ffa300",
    color: "#000",
  },
  "&.MuiButton-contained": {
    background: "#ffa300",
    color: "#000",
  },
  "&.Mui-disabled": {
    color: "#ffa300",
  },
  "&.MuiButton-outlined.Mui-disabled": {
    background: "#ffa300",
    color: "#fff",
  },
  "&.MuiButton-contained.Mui-disabled": {
    background: "#ffa300",
    color: "#fff",
  },
}) as typeof Button;

const ConfirmedButton = styled(Button)({
  "&.Mui-disabled": {
    color: "#0a8a26",
  },
  "&.MuiButton-outlined.Mui-disabled": {
    background: "#0a8a26",
    color: "#fff",
  },
  "&.MuiButton-contained.Mui-disabled": {
    background: "#0a8a26",
    color: "#fff",
  },
  "&.MuiButton-outlined": {
    background: "#640a8a",
    color: "#fff",
  },
  "&.MuiButton-contained": {
    color: "#fff",
    background: "#640a8a",
  },
}) as typeof Button;

const ActionButton = ({
  order,
  minPreparationTime = 0,
}: {
  order: Order;
  minPreparationTime: number;
}) => {
  const { orderDeliveryMutation } = useQueryOrder();

  switch (order.shippingPromise?.status) {
    case "PENDING":
      return (
        <PendingButton
          disabled={orderDeliveryMutation.isLoading}
          variant="contained"
          onClick={() => {
            orderDeliveryMutation.mutate({
              minPreparationTime,
              orderId: order.id,
            });
          }}
        >
          Čeká na potvrzení Woltem - zkusit znovu
        </PendingButton>
      );
    case "RECEIVED":
      return (
        <PendingButton
          variant="contained"
          target={"_blank"}
          disabled={!order.shippingPromise.trackingReference}
          href={order.shippingPromise.trackingReference || ""}
        >
          Wolt zpracovává objednávku
        </PendingButton>
      );
    case "PICKUP_ETA":
      return (
        <ConfirmedButton
          variant="contained"
          target={"_blank"}
          disabled={!order.shippingPromise.trackingReference}
          href={order.shippingPromise.trackingReference || ""}
        >
          Objednávka byla potvrzena Woltem
        </ConfirmedButton>
      );
    case "PICKED_UP":
      return (
        <ConfirmedButton
          variant="contained"
          target={"_blank"}
          disabled={!order.shippingPromise.trackingReference}
          href={order.shippingPromise.trackingReference || ""}
        >
          Wolt doručuje objednávku
        </ConfirmedButton>
      );

    case "DELIVERED":
      return (
        <ConfirmedButton disabled={true} variant="contained">
          Objednávka byla doručena
        </ConfirmedButton>
      );

    case "REJECTED":
      return (
        <RejectedButton disabled={true} variant="contained">
          {order.shippingPromise.message}
        </RejectedButton>
      );

    default:
      return (
        <Button
          disabled={
            orderDeliveryMutation.isLoading ||
            (order.status !== "NEW" && order.status !== "CONFIRMED")
          }
          variant="contained"
          onClick={() => {
            orderDeliveryMutation.mutate({
              minPreparationTime,
              orderId: order.id,
            });
          }}
        >
          Odeslat objednávku Woltem
        </Button>
      );
  }
};

function OrderPage() {
  const [minPreparationTime, setMinPreparationTime] = React.useState<
    Record<string, number>
  >({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    data: { orders = [], totalCount = 0 } = { orders: [], totalCount: 0 },
  } = useQuery(
    [...queryOrderKey, page, rowsPerPage],
    () => list(page * rowsPerPage, rowsPerPage),
    {
      refetchInterval: 1000 * 60,
    }
  );

  return (
    <React.Fragment>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Title>Recent Orders</Title>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>Stav</TableCell>
              <TableCell align={"center"}>Denní ID</TableCell>
              <TableCell align={"center"}>Paragon serial</TableCell>
              <TableCell align={"center"}>Adresa</TableCell>
              <TableCell align={"center"}>Cena objednávky</TableCell>
              <TableCell align={"center"}>Minimální doba přípravy</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <TableRow key={row.orderNumber}>
                <TableCell align={"center"}>{row.status}</TableCell>
                <TableCell align={"center"}>{row.dailyId}</TableCell>
                <TableCell align={"center"}><span title={`Order number: ${row.orderNumber}`}>{row.paragonSerial}</span></TableCell>
                <TableCell align={"center"}>
                  {[
                    row.deliveryAddress.street,
                    row.deliveryAddress.city,
                    row.deliveryAddress.postCode,
                  ]
                    .filter((item) => item)
                    .join(", ")}
                </TableCell>
                <TableCell align={"center"}>
                  {row.price} {row.currency}
                </TableCell>
                <TableCell align={"center"}>
                  <FormControl style={{ margin: "0px 4px" }} fullWidth>
                    <Select
                      labelId="minPreparationTimeLable"
                      id="minPreparationTime"
                      variant={"standard"}
                      value={
                        minPreparationTime[row.id] ||
                        row.shippingPromise?.minPreparationTime ||
                        0
                      }
                      onChange={(event) => {
                        setMinPreparationTime({
                          ...minPreparationTime,
                          [row.id]: event.target.value as number,
                        });
                      }}
                    >
                      {Array.from({ length: 10 }, (_, i) => (
                        <MenuItem key={i} value={i * 10}>
                          {i * 10}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align={"center"}>
                  <ActionButton
                    order={row}
                    minPreparationTime={minPreparationTime[row.id]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}

export default OrderPage;
