import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInputText } from "../formComponents/FormInputText";
import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import { useQueryUser } from "../query/useQueryUser";

type Inputs = {
  email: string;
  password: string;
};

const defaultValues: Inputs = {
  email: "",
  password: "",
};

function LoginPage() {
  const { handleSubmit, control } = useForm<Inputs>({ defaultValues });

  const { mutationLogin } = useQueryUser();

  const onSubmit: SubmitHandler<Inputs> = (data) => mutationLogin.mutate(data);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Přihlášení
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormInputText
            name="email"
            control={control}
            label="Email"
            required={true}
          />
          <FormInputText
            name="password"
            control={control}
            label="Heslo"
            required={true}
            type={"password"}
          />
          {/* register your input into the hook by invoking the "register" function */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Přihlásit se
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
