import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { list, post, patch, remove } from "../api/merchants";
import { useToast } from "../modules/Toast/hooks/useToast";
import { AxiosError } from "axios";

export type Merchant = {
  id: string;
  venueId?: string | null;
  merchantId?: string | null;
  merchantSecret?: string | null;
  objednameSecret: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
  title?: string | null;
  note: string | null;
  smsOnPickup?: string | null;
  smsOnDelivery?: string | null;
};

export const queryMerchantKey = ["merchant"];

export const useQueryMerchant = () => {
  const query = useQuery(queryMerchantKey, () => list());
  const queryClient = useQueryClient();

  const toast = useToast();

  const mutationNewMerchant = useMutation(
    (merchant: Omit<Merchant, "id">) => post(merchant),
    {
      onSuccess: (data: Merchant) => {
        toast.create({
          message: "Merchant created successfully",
          variant: "success",
        });
        queryClient.setQueryData<Array<Merchant>>(
          queryMerchantKey,
          (oldData) => [data, ...(oldData || [])]
        );
      },
      onError: (error: AxiosError) => {
        toast.create({
          message:
            error.response?.status === 409
              ? "Merchant already exists"
              : error.message,
          variant: "error",
        });
      },
    }
  );

  const mutationUpdateMerchant = useMutation(
    ({ merchant, id }: { merchant: Omit<Merchant, "id">; id: string }) =>
      patch(id, merchant),
    {
      onSuccess: (data: Merchant) => {
        toast.create({
          message: "Merchant updated successfully",
          variant: "success",
        });
        queryClient.setQueryData<Array<Merchant>>(queryMerchantKey, (oldData) =>
          oldData ? oldData.map((m) => (m.id === data.id ? data : m)) : [data]
        );
      },
      onError: (error: AxiosError) => {
        toast.create({
          message: error.message,
          variant: "error",
        });
      },
    }
  );

  const mutationRemoveMerchant = useMutation(
    (merchant: Merchant) => remove(merchant.id),
    {
      onSuccess: (id: string) => {
        toast.create({
          message: "Merchant removed successfully",
          variant: "success",
        });
        queryClient.setQueryData<Array<Merchant>>(queryMerchantKey, (oldData) =>
          oldData ? oldData.filter((m) => m.id !== id) : []
        );
      },
      onError: (error: AxiosError) => {
        toast.create({
          message: error.message,
          variant: "error",
        });
      },
    }
  );

  return {
    ...query,
    mutationNewMerchant,
    mutationUpdateMerchant,
    mutationRemoveMerchant,
  };
};
