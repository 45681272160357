import { useMutation, useQueryClient } from "@tanstack/react-query";
import { orderDelivery } from "../api/orders";
import { useToast } from "../modules/Toast/hooks/useToast";

export type ShippingPromiseStates =
  | "PENDING"
  | "REJECTED"
  | "DELIVERED"
  | "RECEIVED"
  | "PICKED_UP"
  | "PICKUP_ETA";

export type ShippingPromise = {
  id: string;
  orderId: string;
  minPreparationTime: number;
  status: ShippingPromiseStates;
  trackingReference: string | null;
  message: string | null;
};

export type Order = {
  id: string;
  orderNumber: string;
  paragonSerial: string;
  dailyId: string;
  status: string;
  currency: string;
  deliveryAddress: {
    street: string;
    city: string;
    postCode: string;
  };
  price: number;
  shippingPromise?: ShippingPromise;
};

export type SendOrder = {
  minPreparationTime: number;
  orderId: string;
};

export const queryOrderKey = ["order"];

const errorMessages: Record<string, string> = {
  err_merchant_not_found: "Merchant not found or not configured",
  err_address_not_complete: "Address is not complete",
  DROPOFF_OUTSIDE_OF_DELIVERY_AREA:
    "Dropoff location is outside of the delivery area",
};

export const useQueryOrder = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const orderDeliveryMutation = useMutation(
    (data: SendOrder) => {
      return orderDelivery(data.orderId, data);
    },
    {
      onSuccess: (shippingPromise) => {
        queryClient.setQueriesData<{ orders: Array<Order>; totalCount: 0 }>(
          queryOrderKey,
          (oldData) => ({
            totalCount: oldData ? oldData.totalCount : 0,
            orders: oldData
              ? oldData.orders?.map((m) =>
                  m.id === shippingPromise.orderId
                    ? {
                        ...m,
                        shippingPromise,
                      }
                    : m
                )
              : [],
          })
        );
        toast.create({
          message: "Order sent to WOLT successfully",
          variant: "success",
        });
      },
      onError: (error: any) => {
        toast.create({
          message:
            errorMessages[error.response.data?.error] ||
            error.response.data?.error ||
            error.message,
          variant: "error",
        });
      },
    }
  );
  return {
    orderDeliveryMutation,
  };
};
