import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  login,
  logout,
  session,
  post,
  removeUser,
  update,
} from "../api/authentication";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useToast } from "../modules/Toast/hooks/useToast";
import { Merchant } from "./useQueryMerchants";

export enum UserRole {
  ADMIN = "Admin",
  USER = "User",
}

export type User = {
  id: string;
  email: string;
  role: "ADMIN" | "USER";
  merchants?: Array<Merchant>;
};

export const userQueryKey = ["user"];
const sessionQueryKey = ["user", "me"];

export const useQueryUser = () => {
  const query = useQuery(sessionQueryKey, () => session(), {
    enabled: false,
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const mutationLogin = useMutation(
    (credentials: { email: string; password: string }) => login(credentials),
    {
      onSuccess: (data: User) => {
        queryClient.setQueryData(sessionQueryKey, data);
        navigate("/");
      },
    }
  );

  const mutationLogout = useMutation(() => logout(), {
    onSuccess: () => {
      queryClient.setQueryData(sessionQueryKey, null);
      navigate("/login");
    },
  });

  const mutationNewUser = useMutation(
    (
      user: Omit<User, "merchants" | "id"> & {
        password: string;
        merchantIds: Array<string>;
      }
    ) => post(user),
    {
      onSuccess: (data: User) => {
        toast.create({
          message: "User created successfully",
          variant: "success",
        });
        return queryClient.invalidateQueries(userQueryKey);
      },
      onError: (error: AxiosError) => {
        toast.create({
          message:
            error.response?.status === 409
              ? "User already exists"
              : error.message,
          variant: "error",
        });
      },
    }
  );

  const mutationUpdateUser = useMutation(
    ({
      user,
      id,
    }: {
      id: string;
      user: Partial<
        Omit<User, "merchants" | "email"> & {
          password: string;
          merchantIds: Array<string>;
        }
      >;
    }) => update(id, user),
    {
      onSuccess: () => {
        toast.create({
          message: "User updated successfully",
          variant: "success",
        });
        return queryClient.invalidateQueries(userQueryKey);
      },
      onError: (error: AxiosError) => {
        toast.create({
          message: error.message,
          variant: "error",
        });
      },
    }
  );

  const mutationRemoveUser = useMutation((id: string) => removeUser(id), {
    onSuccess: () => {
      toast.create({
        message: "User removed successfully",
        variant: "success",
      });
      return queryClient.invalidateQueries(userQueryKey);
    },
    onError: (error: AxiosError) => {
      toast.create({
        message: error.message,
        variant: "error",
      });
    },
  });

  return {
    ...query,
    mutationLogin,
    mutationLogout,
    mutationNewUser,
    mutationRemoveUser,
    mutationUpdateUser,
  };
};
