import { ToastOptions, ToastOptionsInput } from "./Toast.types";

export const createToast = (
  options: ToastOptionsInput,
  actionType?: string
): ToastOptions => ({
  variant: options.variant,
  id: options.id || Math.random(),
  autoHide: 3000,
  createdAt: new Date(),
  actionType,
  ...options,
});
