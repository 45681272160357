import React from "react";

import { ToastOptions } from "../Toast.types";

import { ToastContextType } from "./ToastProvider";
import { Alert, Snackbar } from "@mui/material";

type ToastProps = {
  data: ToastOptions;
  handleRemove: ToastContextType["remove"];
};
//
// type ToastComponentProps = {
//   variant?: UIVariant;
//   size?: "sm";
// };
// const StyledToast = styled(MotionDiv)<ToastComponentProps>`
//   border-radius: var(--radius-s);
//   padding: 1rem 1rem;
//   width: 310px;
//   border-width: 1px;
//   font-size: 0.875rem;
//   margin-bottom: 0.5rem;
//   position: relative;
//   display: flex;
//   align-items: flex-start;
//   background: #1e1b1b;
//   color: white;
//
//   /** variant */
//   ${(p) => {
//     if (p.variant) {
//       return `
//       background-color: var(--${p.variant});
//       color: var(--text-on-bg);
//       border-color: rgba(0,0,0,0.05);
//       `;
//     }
//   }}
//   /** variant */
//   ${(p) => {
//     if (p.size === "sm") {
//       return `
//         width: auto;
//       `;
//     }
//   }}
// `;
//
// const ToastTitle = styled.div`
//   line-height: 1.4;
//   font-weight: 400;
//   font-size: 0.875rem;
//   margin-top: 2px;
// `;
//
// const ToastDesc = styled.p`
//   margin-top: 0.4rem;
//   line-height: 1.4;
//   color: var(--text-on-bg);
//   font-weight: 400;
//   opacity: 0.8;
// `;
//
// const ToastContent = styled.div`
//   padding-right: 1rem;
//   flex-grow: 1;
// `;
//
// const StyledClose = styled(BiX)`
//   font-size: 1.5rem;
//   position: absolute;
//   right: 1rem;
//   top: 1rem;
//   cursor: pointer;
//   opacity: 0.5;
//   &:hover {
//     opacity: 1;
//   }
// `;
//
// const IconContainer = styled.div<{ variant?: UIVariant }>`
//   min-width: 20px;
//   min-height: 20px;
//   font-size: 1.5rem;
//   ${(p) => `color: var(--${p.variant});`}
//   & > * {
//     display: flex;
//     justify-content: center;
//   }
//   &:not(:last-child) {
//     margin-right: 0.75rem;
//   }
// `;

export const Toast = (props: ToastProps) => {
  const { data, handleRemove } = props;

  const [open, setOpen] = React.useState(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    handleRemove(data.id);
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={data.autoHide}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={data.variant}
        sx={{ width: "100%" }}
      >
        {data.message}
      </Alert>
    </Snackbar>
  );
};
