import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../components/Title";
import { Box, Button, Modal, Paper } from "@mui/material";
import { Merchant, useQueryMerchant } from "../query/useQueryMerchants";
import { NewMerchantForm } from "../components/NewMerchantForm";

const Row = ({
  row,
  setEditing,
}: {
  row: Merchant;
  setEditing: () => void;
}) => {
  const { mutationRemoveMerchant } = useQueryMerchant();

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>{row.title}</TableCell>
        <TableCell>
          Objedname secret - {row.objednameSecret}
          <br />
          Wolt merchant ID - {row.merchantId}
          <br />
          Wolt merchant Key - {row.merchantSecret}
        </TableCell>
        <TableCell>{row.contactPhone}</TableCell>
        <TableCell>{row.contactEmail}</TableCell>
        <TableCell>{row.note}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={setEditing}>
            Upravit
          </Button>
          <Button
            sx={{ marginLeft: 1 }}
            variant="contained"
            color={"error"}
            onClick={() => mutationRemoveMerchant.mutate(row)}
          >
            Odstranit
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "15%",
  left: "15%",
  right: "15%",
  // transform: "translate(-25%, 0)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MerchantPage() {
  const { data: rows = [] } = useQueryMerchant();
  const [addNew, setAddNew] = React.useState(false);
  const [editing, setEditing] = React.useState<Merchant | null>(null);

  return (
    <React.Fragment>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Title>Merchants</Title>
          <Button variant="contained" onClick={() => setAddNew(true)}>
            {!addNew ? "Přidat" : "Zrušit"}
          </Button>
        </Box>

        <Modal
          open={addNew || !!editing}
          onClose={() => (addNew ? setAddNew(false) : setEditing(null))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {addNew && <NewMerchantForm onSuccess={() => setAddNew(false)} />}
            {editing && (
              <NewMerchantForm
                onSuccess={() => setEditing(null)}
                editData={editing as Merchant}
              />
            )}
          </Box>
        </Modal>

        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Název</TableCell>
              <TableCell>Secrets</TableCell>
              <TableCell>Kontaktní telefon</TableCell>
              <TableCell>Kontaktní email</TableCell>
              <TableCell>Poznámka</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={row.id} row={row} setEditing={() => setEditing(row)} />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
}

export default MerchantPage;
