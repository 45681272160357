import { io } from "socket.io-client";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Order, queryOrderKey, ShippingPromise } from "./useQueryOrder";
import { api } from "../api";
import { useQueryUser } from "./useQueryUser";

const url = api
  .getUri()
  .replace(/http(s?):\/\/(.+?)(?=\/).*/, "ws$1://$2/user/");
export const useReactQuerySubscription = () => {
  const queryClient = useQueryClient();
  const queryUser = useQueryUser();
  React.useEffect(() => {
    const websocket = io(`${url}${queryUser.data?.id}`, {
      withCredentials: true,
      transports: ["websocket"],
      secure: true,
    });
    websocket.on("connect", () => {
      console.log("connected");
    });
    websocket.on("ORDER:UPDATED", (order: Order) => {
      queryClient.setQueriesData<{ orders: Order[]; totalCount: number }>(
        queryOrderKey,
        (oldData) => {
          const update = (entity: Order) => {
            return entity.id === order.id ? { ...entity, ...order } : entity;
          };
          return {
            totalCount: oldData?.totalCount || 0,
            orders: oldData?.orders?.map(update) || [],
          };
        }
      );
    });

    websocket.on(
      "SHIPPING_PROMISE:UPDATED",
      (shippingPromise: ShippingPromise) => {
        console.log("shippingPromise", shippingPromise);
        queryClient.setQueriesData<{ orders: Order[]; totalCount: number }>(
          queryOrderKey,
          (oldData) => {
            const update = (entity: Order) =>
              entity.id === shippingPromise.orderId
                ? { ...entity, shippingPromise }
                : entity;
            return {
              totalCount: oldData?.totalCount || 0,
              orders: oldData?.orders?.map(update) || [],
            };
          }
        );
      }
    );

    return () => {
      websocket.close();
    };
  }, [queryClient, queryUser.data?.id]);
};
