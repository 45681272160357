import { SelectProps } from "@mui/material";
import { Controller } from "react-hook-form";

import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

export type FormSelectProps = {
  name: string;
  control: any;
  required?: boolean;
} & SelectProps;

export const ReactHookFormSelect = ({
  name,
  label,
  control,
  children,
  ...props
}: FormSelectProps) => {
  const labelId = `${name}-label`;
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <Select labelId={labelId} {...props} {...field}>
            {children}
          </Select>
        )}
        name={name}
        control={control}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
