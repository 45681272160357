import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../components/Title";
import { Box, Button, Modal, Paper, TablePagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useQueryUser, User, userQueryKey } from "../query/useQueryUser";
import { listUsers } from "../api/authentication";
import { NewUserForm } from "../components/NewUserForm";

const Row = ({ row, setEditing }: { row: User; setEditing: () => void }) => {
  const { mutationRemoveUser } = useQueryUser();

  return (
    <TableRow key={row.id}>
      <TableCell align={"center"}>{row.email}</TableCell>
      <TableCell align={"center"}>{row.role}</TableCell>
      <TableCell align={"center"}>
        {(row.merchants || [])
          .map((merchant) => merchant.title || merchant.id)
          .join(", ")}
      </TableCell>
      <TableCell align={"center"}>
        <Button variant="contained" onClick={setEditing}>
          Upravit
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          variant="contained"
          color={"error"}
          onClick={() => mutationRemoveUser.mutate(row.id)}
        >
          Odebrat
        </Button>
      </TableCell>
    </TableRow>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "15%",
  left: "15%",
  right: "15%",
  // transform: "translate(-25%, 0)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UserPage() {
  const [addNew, setAddNew] = React.useState(false);
  const [editing, setEditing] = React.useState<User | null>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    data: { users = [], totalCount = 0 } = { users: [], totalCount: 0 },
  } = useQuery([...userQueryKey, page, rowsPerPage], () =>
    listUsers(page * rowsPerPage, rowsPerPage)
  );

  return (
    <React.Fragment>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Title>Uživatelé</Title>
          <Button variant="contained" onClick={() => setAddNew(!addNew)}>
            {!addNew ? "Přidat uživatele" : "Zrušit"}
          </Button>
        </Box>

        <Modal
          open={addNew || !!editing}
          onClose={() => (addNew ? setAddNew(false) : setEditing(null))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {addNew && <NewUserForm onSuccess={() => setAddNew(false)} />}
            {editing && (
              <NewUserForm
                onSuccess={() => setEditing(null)}
                editData={editing as User}
              />
            )}
          </Box>
        </Modal>

        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>Email</TableCell>
              <TableCell align={"center"}>Role</TableCell>
              <TableCell align={"center"}>Merchants</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <Row key={row.id} row={row} setEditing={() => setEditing(row)} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}

export default UserPage;
