import { Navigate, Route, Routes } from "react-router-dom";
import { useQueryUser } from "../query/useQueryUser";
import React, { useEffect, useMemo } from "react";
import {
  AppBarProps,
  Box,
  Container,
  createTheme,
  CssBaseline,
  Divider,
  Link,
  List,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useReactQuerySubscription } from "../query/useReactQuerySubscription";
import { MainListItems } from "../components/Menu";
import { ToastViewControl } from "../modules/Toast/components/ToastViewControl";
import OrderPage from "./OrderPage";
import MerchantPage from "./MerchantPage";
import UserPage from "./UserPage";

export const ProtectedLayout = () => {
  const queryUser = useQueryUser();
  useReactQuerySubscription();

  useEffect(() => {
    queryUser.refetch();
    // eslint-disable-next-line
  }, []);

  const isAdmin = useMemo(
    () => queryUser.data?.role === "ADMIN",
    [queryUser.data?.role]
  );

  if (queryUser.isLoading) {
    return <div>Loading...</div>;
  }

  if (!queryUser.data) {
    return <Navigate to="/login" />;
  }

  const drawerWidth: number = isAdmin ? 160 : 0;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  const defaultTheme = createTheme();
  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <ToastViewControl />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Wolt - Objedname
              </Typography>

              <Box component={"div"} sx={{ display: "flex" }}>
                <Typography component="h1" variant="h6" color="inherit">
                  {queryUser.data.email}
                </Typography>
                <Link
                  underline={"none"}
                  component="button"
                  variant="h6"
                  color="inherit"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    queryUser.mutationLogout.mutate();
                  }}
                >
                  Logout
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
          {isAdmin && (
            <Drawer variant="permanent" open={true}>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                {/*<IconButton onClick={toggleDrawer}>*/}
                {/*  <ChevronLeftIcon />*/}
                {/*</IconButton>*/}
              </Toolbar>
              <Divider />
              <List component="nav">
                <MainListItems />
                {/*<Divider sx={{ my: 1 }} />*/}
                {/*{secondaryListItems}*/}
              </List>
            </Drawer>
          )}
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Routes>
                <Route path="/" element={<OrderPage />} />
                {isAdmin && (
                  <Route path="/merchant" element={<MerchantPage />} />
                )}
                {isAdmin && <Route path="/user" element={<UserPage />} />}
              </Routes>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};
