import React, { PropsWithChildren, Reducer } from "react";

import { ToastOptions, ToastOptionsInput } from "../Toast.types";
import { createToast } from "../toast.utils";

export type ToastContextType = {
  toasts: ToastOptions[];
  create: (toast: ToastOptionsInput) => void;
  remove: (toastId: ToastOptions["id"]) => void;
};

export const ToastContext = React.createContext<ToastContextType>(
  {} as ToastContextType
);

type ToastActionCreate = {
  type: "CREATE";
  payload: ToastOptionsInput;
};

type ToastActionRemove = {
  type: "REMOVE";
  payload: ToastOptions["id"];
};

type ToastAction = ToastActionCreate | ToastActionRemove;

const toastReducer: Reducer<ToastOptions[], ToastAction> = (state, action) => {
  switch (action.type) {
    case "CREATE": {
      return [...state, createToast(action.payload)];
    }
    case "REMOVE": {
      return state.filter((toast) => toast.id !== action.payload);
    }
    default:
      return state;
  }
};

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [toasts, dispatch] = React.useReducer(toastReducer, []);

  const create: ToastContextType["create"] = React.useCallback(
    (toast: ToastOptionsInput) => {
      dispatch({
        type: "CREATE",
        payload: toast,
      });
    },
    [dispatch]
  );

  const remove: ToastContextType["remove"] = React.useCallback(
    (toastId) => {
      dispatch({
        type: "REMOVE",
        payload: toastId,
      });
    },
    [dispatch]
  );

  return (
    <ToastContext.Provider
      value={{
        toasts,
        create,
        remove,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
