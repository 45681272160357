import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
export const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.status === 401 ||
      error.response.data.error === "err_no_access_token"
    ) {
      window.localStorage.removeItem("user");
      document.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
