import { api } from "./index";
import { AxiosResponse } from "axios";
import { User } from "../query/useQueryUser.js";

export const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<User> =>
  api
    .post("/authenticate", { email, password })
    .then((response: AxiosResponse<User>) => response.data);

export const session = (): Promise<User> =>
  api.get("/session").then((response: AxiosResponse<User>) => response.data);

export const logout = (): Promise<void> => api.delete("/authenticate");

export const listUsers = (
  offset: number,
  limit: number
): Promise<{ users: User[]; totalCount: number }> =>
  api
    .get("/user", {
      params: {
        offset,
        limit,
      },
    })
    .then((response: AxiosResponse<User[]>) => ({
      users: response.data,
      totalCount: parseInt(response.headers["x-count"], 10),
    }));

export const post = (data: {
  email: string;
  password: string;
  role: "USER" | "ADMIN";
  merchantIds: string[];
}): Promise<User> =>
  api
    .post("/user", data)
    .then((response: AxiosResponse<User>) => response.data);

export const removeUser = (id: string): Promise<void> =>
  api.delete(`/user/${id}`);

export const update = (
  id: string,
  data: {
    password?: string;
    role?: "USER" | "ADMIN";
    merchantIds?: string[];
  }
): Promise<void> => api.patch(`/user/${id}`, data);
