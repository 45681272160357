import { FormInputText } from "../formComponents/FormInputText";
import { Button, MenuItem, Typography } from "@mui/material";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryMerchant } from "../query/useQueryMerchants";
import { useQueryUser, User, UserRole } from "../query/useQueryUser";
import ReactHookFormSelect from "../formComponents/FormSelect";
import Grid2 from "@mui/material/Unstable_Grid2";

type Inputs = {
  email: string;
  password: string;
  role: "ADMIN" | "USER";
  merchantIds: string[];
};

const defaultValues: Inputs = {
  email: "",
  password: "",
  role: "USER",
  merchantIds: [],
};

export const NewUserForm = ({
  onSuccess,
  editData,
}: {
  onSuccess: () => void;
  editData?: User;
}) => {
  const { control, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: {
      ...defaultValues,
      ...(editData && {
        ...editData,
        merchantIds: editData.merchants?.map((m) => m.id) || [],
      }),
    },
  });

  const { data: merchants = [] } = useQueryMerchant();
  const { mutationNewUser, mutationUpdateUser } = useQueryUser();

  const onNewSubmit: SubmitHandler<Inputs> = async (data) => {
    await mutationNewUser.mutate(data, {
      onSuccess: () => {
        reset();
        onSuccess();
      },
    });
  };

  const onEditSubmit: SubmitHandler<Inputs> = async (data) => {
    editData &&
      (await mutationUpdateUser.mutate(
        { user: data, id: editData.id as string },
        {
          onSuccess: () => {
            onSuccess();
          },
        }
      ));
  };

  return (
    <>
      <Typography variant="h6" component="h2">
        {editData ? "Edit User" : "New User"}
      </Typography>
      <Grid2
        container
        spacing={1}
        // direction="row"
        // spacing={2}
        component="form"
        onSubmit={handleSubmit(editData ? onEditSubmit : onNewSubmit)}
        noValidate
        justifyContent="center"
        alignItems="center"
      >
        <Grid2 xs={12}>
          <FormInputText
            name="email"
            control={control}
            label="Email"
            required={true}
            type={"email"}
          ></FormInputText>
        </Grid2>
        <Grid2 xs={12}>
          <FormInputText
            name="password"
            control={control}
            label="Password"
          ></FormInputText>
        </Grid2>
        <Grid2 xs={2}>
          <ReactHookFormSelect name={"role"} label={"Role"} control={control}>
            {Object.keys(UserRole).map((role) => (
              <MenuItem key={role} value={role}>
                {UserRole[role as keyof typeof UserRole]}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid2>
        <Grid2 xs={10}>
          <ReactHookFormSelect
            name={"merchantIds"}
            label={"Merchants"}
            control={control}
            multiple
          >
            {merchants.map((merchant) => (
              <MenuItem key={merchant.title || merchant.id} value={merchant.id}>
                {merchant.title || `${merchant.id}`}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid2>
        <Grid2 xs={12}>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};
