import { FormInputText } from "../formComponents/FormInputText";
import { Button, Typography } from "@mui/material";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Merchant, useQueryMerchant } from "../query/useQueryMerchants";
import Grid2 from "@mui/material/Unstable_Grid2";

type Inputs = {
  title: string | null;
  merchantId: string | null;
  merchantSecret: string | null;
  objednameSecret: string | null;
  venueId: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
  note: string | null;
  smsOnPickup: string | null;
  smsOnDelivery: string | null;
};

const defaultValues: Inputs = {
  title: "",
  merchantId: "",
  merchantSecret: "",
  objednameSecret: "",
  contactPhone: "+420",
  contactEmail: "",
  venueId: "",
  note: "",
  smsOnPickup:
    "Vaši objednávku právě převzal kurýr, zde můžete sledovat její průběh: TRACKING_LINK",
  smsOnDelivery:
    "Vaše objednávka se připravuje, zde můžete sledovat její průběh: TRACKING_LINK",
};

export const NewMerchantForm = ({
  onSuccess,
  editData,
}: {
  onSuccess: () => void;
  editData?: Merchant;
}) => {
  const { control, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: {
      ...defaultValues,
      ...(editData || {}),
    },
  });

  const { mutationNewMerchant, mutationUpdateMerchant } = useQueryMerchant();

  const onEditSubmit: SubmitHandler<Inputs> = async (data) => {
    await mutationUpdateMerchant.mutate(
      { merchant: data, id: editData?.id as string },
      {
        onSuccess: () => {
          onSuccess();
        },
      }
    );
  };

  const onNewSubmit: SubmitHandler<Inputs> = async (data) => {
    await mutationNewMerchant.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          reset();
          onSuccess();
        },
      }
    );
  };

  return (
    <>
      <Typography variant="h6" component="h2">
        {editData ? "Edit Merchant" : "New Merchant"}
      </Typography>
      <Grid2
        container
        spacing={1}
        // direction="row"
        // spacing={2}
        component="form"
        onSubmit={handleSubmit(editData ? onEditSubmit : onNewSubmit)}
        noValidate
        justifyContent="center"
        alignItems="center"
      >
        <Grid2 xs={12}>
          <FormInputText
            name="title"
            control={control}
            label="Název"
          ></FormInputText>
        </Grid2>
        <Grid2 xs={12}>
          <FormInputText
            required={true}
            name="objednameSecret"
            control={control}
            label="Objedname Secret"
          ></FormInputText>
        </Grid2>
        <Grid2 xs={4}>
          <FormInputText
            name="merchantId"
            control={control}
            label="Merchant ID"
          ></FormInputText>
        </Grid2>
        <Grid2 xs={4}>
          <FormInputText
            name="merchantSecret"
            control={control}
            label="Merchant Secret"
          ></FormInputText>
        </Grid2>
        <Grid2 xs={4}>
          <FormInputText
            name="venueId"
            control={control}
            label="Venue ID"
          ></FormInputText>
        </Grid2>
        <Grid2 xs={6}>
          <FormInputText
            name="contactPhone"
            control={control}
            label="Kontaktní telefon"
            type={"tel"}
          ></FormInputText>
        </Grid2>
        <Grid2 xs={6}>
          <FormInputText
            name="contactEmail"
            control={control}
            label="Kontaktní email"
            type={"email"}
          ></FormInputText>
        </Grid2>
        <Grid2 xs={12}>
          <FormInputText
            name="note"
            control={control}
            label="Poznámka"
            type={"note"}
          ></FormInputText>
        </Grid2>
        <Grid2 xs={12}>
          <FormInputText
            name="smsOnDelivery"
            control={control}
            label="Sms zpráva při přijetí objednávky"
            type={"text"}
          ></FormInputText>
        </Grid2>
        <Grid2 xs={12}>
          <FormInputText
            name="smsOnPickup"
            control={control}
            label="Sms zpráva při vyzvednutí objednávky"
            type={"text"}
          ></FormInputText>
        </Grid2>
        <Grid2 xs={12}>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};
