import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

export const MainListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate("/")}>
        <ListItemText primary="Orders" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/merchant")}>
        <ListItemText primary="Merchants" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/user")}>
        <ListItemText primary="Users" />
      </ListItemButton>
    </React.Fragment>
  );
};
