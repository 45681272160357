import { api } from "./index";
import { AxiosResponse } from "axios";
import { Order, ShippingPromise } from "../query/useQueryOrder";

export const list = (
  offset: number,
  limit: number
): Promise<{ orders: Array<Order>; totalCount: number }> =>
  api
    .get("/order", {
      params: {
        limit,
        offset,
      },
    })
    .then((response: AxiosResponse<Array<Order>>) => {
      return {
        orders: response.data,
        totalCount: parseInt(response.headers["x-count"], 10),
      };
    });

export const orderDelivery = (
  orderId: string,
  data: { minPreparationTime: number }
): Promise<ShippingPromise> =>
  api
    .post(`/order/${orderId}/delivery`, data)
    .then((response: AxiosResponse<ShippingPromise>) => response.data);
