import { api } from "./index";
import { AxiosResponse } from "axios";
import { Merchant } from "../query/useQueryMerchants";

export const list = (): Promise<Array<Merchant>> =>
  api
    .get("/merchant")
    .then((response: AxiosResponse<Array<Merchant>>) => response.data);

export const post = (data: Omit<Merchant, "id">): Promise<Merchant> =>
  api
    .post("/merchant", data)
    .then((response: AxiosResponse<Merchant>) => response.data);

export const patch = (
  id: string,
  data: Omit<Merchant, "id">
): Promise<Merchant> =>
  api.patch(`/merchant/${id}`, data).then((_response: AxiosResponse<void>) => ({
    ...data,
    id,
  }));

export const remove = (id: string): Promise<string> =>
  api.delete(`/merchant/${id}`).then((_response: AxiosResponse<void>) => id);
