import { useContext } from "react";

import { Toast } from "./Toast";
import { ToastContext } from "./ToastProvider";

export const ToastViewControl = () => {
  const toastContext = useContext(ToastContext);

  return toastContext.toasts.length ? (
    <>
      {toastContext.toasts.map((item) => (
        <Toast handleRemove={toastContext.remove} key={item.id} data={item} />
      ))}
    </>
  ) : null;
};
